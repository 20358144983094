<template>
    <the-header ></the-header>
    <slot :key="$route.fullPath"/>
    <the-footer ></the-footer>
</template>

<script>
// @ is an alias to /src
import TheHeader from '@/components/layouts/TheHeader.vue';
import TheFooter from '@/components/layouts/TheFooter.vue';



export default {
  name: 'DefaultLayout',
  components: {
    TheHeader,
    TheFooter
  },
  
}
</script>